<template>
  <div class="main-content" style="
    padding-bottom: 10vh !important
      background-repeat: no-repeat;
      -webkit-background-size: cover;
      -moz-background-size: cover;
      -o-background-size: cover;
      background-size: cover;
      width: 100%;
      background-position: center;
    ">
    <div class="dashboard-banner position-relative">
      <div class="d-flex justify-content-center align-items-end">
        <!-- <h4 class="mt-5 mx-4 text-center text-primary">{{ $t("dashboard") }}</h4> -->
      </div>
      <div class="position-absolute h-100 w-100" style="background: #d2d2d24d" v-if="isLoading">
        <div class="px-2 py-1 rounded" style="
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            background: #add8e6;
          ">
          <!-- <p class="mb-0">{{ $t("loading...") }}</p> -->
        </div>
      </div>
    </div>
    <div class="m-3 mx-3 mt-4">
      <div class="header">
          <img src="../../../assets/images/etnex_buy/back_icon.png" alt="Back" class="back-arrow-icon" @click="goBack">
          <h1 class="buy_text text">{{ $t("deposit") }}</h1>
          <i class="fa fa-history info-icon" aria-hidden="true" @click="goHistory"></i>
          <!-- <img src="../../../assets/images/etnex_buy/question.png" alt="Info" class="info-icon"> -->
      </div>
      <!-- <h2 class="text_h2_etnex text">{{ $t("etnex_purchase") }}</h2> -->
      <div class="etnex-purchase-container">
        <div class="">
          <div class="purchase-details">
            <h3 class="purchase_text text head_style2" style="margin-bottom: 1.5rem;">{{ $t("walletaddress") }}</h3>
            <div class="qr-code-container" style="margin-bottom: 1.5rem;">
              <qrcode
                :size="size"
                :cls="qrCls"
                :value="newCoinAddress"
              ></qrcode>
            </div>
            <!-- <div class="contract-address-container">
              <span class="address_text text">{{ $t("con_address") }}</span>
              <span class="address">Tbxnnc...8sjd7Y
                <span class="copy-icon">
                  <img src="../../../assets/images/etnex_buy/copy.png" alt="copy" class="copy-icon">
                </span>
              </span>
            </div> -->
          </div>
          <div class="">
            <h6 class="text-10 text-white">
              {{ "USDT " + $t("deposit_address") }}
            </h6>   

            <div class="d-flex justify-content-between black-dark-blue-card" style="align-items: center;">
              <b-row style="align-items: center;">
                <b-col cols="10" class="text-center pr-0">
                  <div class="text-white text-12 text-overflow">
                    {{ newCoinAddress }}
                  </div>
                </b-col>
                <b-col cols="2" class="text-right">
                  <div class="text-16 text-white">
                    <b-link
                      v-clipboard="() => newCoinAddress"
                      v-clipboard:success="clipboardSuccessHandler"
                      v-clipboard:error="clipboardErrorHandler">
                      <img src="../../../assets/images/etnex_wallet/copy.png" alt="Info" class="info-icon" width="20px" height="20px">
                    </b-link>
                  </div>
                </b-col>
              </b-row>
              
              
            </div>    

            <h6 class="mb-2 mt-4 text-10 text-white">
              {{ $t("network") }}
            </h6>      
            <div class="black-dark-blue-card" style="align-items: center;">
              <h5 class="text-15 mb-0 text-white">BEP20</h5>
            </div>   
          </div>

        </div>
      </div>
    </div>
    <Dialog ref="msg"></Dialog>
    <!-- <Success v-if="SuccessModal" @closePopup="SuccessModal = false" :message="successMessage" /> -->
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { getWallet } from "../../../system/api/api";
import { handleError } from "../../../system/handleRes";
import Qrcode from "v-qrcode/src/index";
import Dialog from "../../../components/dialog.vue";
// import Success from '../popup/profilesuccess.vue';
export default {
  components: {
    Dialog,
    Qrcode
  },
  validations: {
    newCoinType: {
      required,
    },
    newAmount: {
      required,
    },
    tx_id: {
      required,
    },
    tx_id2: {
      required,
    },
    sec_pwd: {
      required,
    },
  },
  data() {
    return {
      qrCls: "qrcode",
      size: 210,

      newAmount: "",
      newCoinAddress: "",
      newCoinType: "",
      coin: null,
      coinList: [],
      coinOptions: [],
      isLoading: false,
      tx_id: "",
      tx_id2: "",
      address: "",
      sec_pwd: "",
      dfi_paid: "",
      option_paid: "",
      selected: "1",
      globalDfiAddress: "",
      globalDfiPrice: "",
      finalAmount: "",
      finalAmount2: "",
      depositName: "",
      is100: false,

    };
  },
  props: ["success"],
  methods: {
    goBack() {
      this.$router.go(-1)
    },
    goHistory() {
      this.$router.push("/web/deposit/depositHistory");
    },
    clipboardSuccessHandler() {
      this.$refs.msg.makeToast("success", this.$t("copied"));
    },
    clipboardErrorHandler() {},

    getCoin() {
      var result = getWallet();
      var self = this;
      self.isLoading = true;

      result
        .then(function (value) {
          if (value.data.code == 0) {
            self.newCoinAddress = value.data.data;
          }
          self.isLoading = false;
        })
        .catch(function (error) {
          self.$refs.msg.makeToast("warning", self.$t(handleError(error)));
          self.isLoading = false;
        });
    },
  },

  mounted() {
    this.getCoin();
  },
};
</script>

<style scoped>
/* text css */
.text-overflow {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.text{
  color: var(--Main, #FFF);
  font-family: Poppins;
  font-style: normal;
}

.buy_text{
  font-size: 20px;
  font-weight: 600;
  line-height: 34px;
  margin-top: 7px;
}
.purchase_text{
  font-size: 18px;
  font-weight: 600;
  line-height: 22px; 
}
.address_text{
  font-size: 14px;
  font-weight: 400;
  line-height: 18px;
}

.statement_text{
  font-size: 16px;
  font-weight: 600;
  line-height: 22px;
}

.hash_text{
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
}

.amounts_text{
  font-size: 12px;
  font-weight: 500;
  line-height: 22px;
}

.upload_text{
  font-size: 10px;
  font-weight: 500;
  line-height: 16px;
}

.upload-text{
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

.address{
  color: var(--Secondary, #7E8088);
  font-family: Poppins;
  font-size: 12px;
  font-style: normal;
  font-weight: 400;
  line-height: 16px;
}

.text_h2_etnex{
  font-size: 18px;
  font-weight: 600;
  line-height: 22px;
}

/* text css end */

.qrcode{
  padding: 25px 22px 15px 25px;
  background-color: #fff;
  border-radius: 16px;
}
.package-amounts{
  font-size: 14px;
  font-weight: 400;
  line-height: 18px; /* 128.571% */
}

.hash_input{
  border-radius: 7px !important;
  border: 1px solid var(--Neon-Blue, #0BDDCC) !important;
  background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%)) !important;
}

.qr-code-container {
  display: flex;
  justify-content: center;
  width: 100%;
}

.contract-address-container {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 12px;
  color: #fff;
  font-family: 'Poppins', sans-serif;
  border-bottom: 1px solid #393737;
}

.address {
  margin-right: 5px;
}

.copy-icon {
  cursor: pointer;
}

.etnex-purchase-container {
  max-width: 100%;
  margin: auto;
  color: white;
  padding: 12px;
  font-family: 'Poppins', sans-serif;
}

.header {
  display: flex;
  /* padding: 0px 16px; */
  margin-top: 4rem !important;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 24px;
}

.back-arrow-icon, .info-icon {
  cursor: pointer;
}

.info-icon {
    cursor: pointer;
    color: #fff;
    font-size: 18px;
}

.purchase-content h2 {
  margin-bottom: 16px;
}

.purchase-details {
  border-radius: 8px;
  margin-bottom: 16px;
}

.qr-code {
  margin: 16px 0;
}


.make-purchase-button {
  width: 100%;
  border-radius: 8px;
  background: var(--Primary, #3BEDEA);
  color: rgb(0, 0, 0);
  padding: 12px;
  border: none;
  margin-top: 16px;
  color: var(--Dark-Purple, #251740);
  text-align: center;
  font-family: Poppins;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 21px;
}


.purchase-statement-container {
  padding: 10px;
  color: white;
  font-family: 'Poppins', sans-serif;
}

.hash-input label,
.hash-input input {
  display: block;
  width: 100%;
  margin-bottom: 10px;
}

.hash-input input {
  background: #333;
  border: none;
  padding: 10px;
  border-radius: 5px;
  color: #ccc;
}




.upload-button {
  padding: 10px;
  background: #3BEDEA;
  border: none;
  border-radius: 5px;
  color: black;
  cursor: pointer;
}



.package-amounts {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.amount-option {
  flex: 1 1 calc(33.333% - 10px);
  border-radius: 8px;
  background: var(--Menu-Dark, #222223);
  cursor: pointer;
  padding: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: white;
  text-align: center;
  box-sizing: border-box;
  position: relative;
}

.amount-option input[type="radio"] {
  display: none;
}

.amount-option span {
  display: block;
}



.amount-option input[type="radio"]:checked ~ .amount-content::before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  border: 1px solid #3BEDEA;
  border-radius: 5px;
  pointer-events: none;
}

.upload-statement {
  display: flex;
  flex-direction: column;
  align-items: center;
 
}

.upload-btn {
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 10px 20px;
  border-radius: 7px;
  border: 1px solid var(--Neon-Blue, #0BDDCC);
  background: var(--bg-shade, linear-gradient(180deg, rgba(52, 58, 66, 0.53) 0%, rgba(0, 0, 0, 0.81) 100%));
  border: 1px solid #3BEDEA;
  cursor: pointer;
  transition: all 0.3s ease;
  text-transform: none;
}


.upload-icon {
  margin-right: 5px;
}

/* .form-deposit {
  font-size: 16px !important;
  padding: 0px;
  background-color: #1D1D20;
  border-radius: 8px !important;
  border: 1px solod #0BDDCC;
} */
</style>
